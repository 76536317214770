import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom/client'
import App from './App'
import './i18n'
import 'intro.js/introjs.css'
import './index.css'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b484ee8db84b6238f3aa27779f43f011@o4508070729154560.ingest.de.sentry.io/4508070732955728",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["testapi.mestredaconducao.pt", /^https:\/\/testapi.mestredaconducao.pt/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// const container = document.getElementById(“app”);
// const root = createRoot(container);
// root.render(<App />);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
)
